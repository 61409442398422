import React from 'react';

import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import BannerLanding from '../../components/BannerMobile';

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Digital Publications - Mills Publishing</title>
      <meta name="description" content="Landing Page" />
    </Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Advertising Right in Patrons’ Hands</h2>
          </header>
          <p>
            Advertisers will be able to reach patrons right from their mobile or
            tablet devices. Ads rotate with every new page view giving your
            business more exposure. They are lightweight, fast, easy to read,
            and easy to use. We use a dark mode theme to help reduce light
            distraction in theater venues.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <a href="#contact" className="image">
            <img
              src="/group-images/mobile-presentation.jpg"
              alt="Mobile web app examples"
            />
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Mobile-friendly Digital Publications</h3>
              </header>
              <p>
                Our new mobile-friendly digital playbills give our clients a
                fast and easy reading experience on their mobile or tablet
                devices.
              </p>
              <p>
                <strong>See Examples Live:</strong>
              </p>
              <ul className="actions">
                <li>
                  <a
                    href="https://balletwest.millspub.com"
                    className="button small scrolly arts"
                  >
                    Ballet West
                  </a>
                </li>
                <li>
                  <a
                    href="https://usuoondemand.millspub.com"
                    className="button small scrolly arts"
                  >
                    Utah Symphony
                  </a>
                </li>
              </ul>
              <p>Contact us on the form below for more information.</p>
            </div>
          </div>
        </section>
        <section>
          <iframe
            title="example"
            allowfullscreen
            allow="fullscreen"
            style={{ width: 768 + 'px', height: 516 + 'px' }}
            src="//e.issuu.com/embed.html?d=bw_nine_sinatra_songs_nov20_issuu&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=millspublishinginc"
          ></iframe>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Traditional Digital Publications</h3>
              </header>
              <p>
                We still offer a more traditional publication experience with a
                digital page flipping format.
              </p>
              <p>Contact us on the form below for more information.</p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default Landing;
